<template lang="pug">
// Ideally for a11y, dialogs should attach to the button that triggered them.
// But this is not possible in the header, given Vuetify's CSS.
// We attach to #main (rather than #app) because of
// https://dequeuniversity.com/rules/axe/3.5/region
div.the-commentary-verse-picker.d-flex.align-center.justify-space-between
  VersePickerDialog(
    v-model:range="vidRange"
    v-model:open="versePickerDialogOpen"
    :verse-picker-tid="localTid"
    :local-tid="localTid"
    :history="userRecentStore.scripture"
  )
    template(#activator="activatorProps")
      VBtn.the-commentary-verse-picker__verses-button(
        variant="text"
        v-bind="activatorProps['props']"
        title="Change verses"
        aria-label="Change verses"
      ) {{ humanReadableRange.value || "…" }}
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useCommentaryFiltersStore } from "~/stores/useCommentaryFiltersStore";
import { useUserRecentStore } from "~/stores/useUserRecentStore";
import { injectRequired } from "~/injectRequired";
import { useComputedResultAsync } from "~/composables/useComputedResultAsync";
import { ScriptureClientKey } from "~/injectionKeys";

const scriptureClient = injectRequired(ScriptureClientKey);

const commentaryFiltersStore = useCommentaryFiltersStore();
const runtimeConfig = useRuntimeConfig();
const userRecentStore = useUserRecentStore();

const versePickerDialogOpen = ref<boolean>(false);

interface Props {
  /**
   * The ID of the user's preferred local translation.
   */
  localTid: string;
}

const props = defineProps<Props>();

const vidRange = computed({
  get: () =>
    commentaryFiltersStore.getVidRange(
      runtimeConfig.public.commentary.defaultVids,
    ),
  set(value) {
    commentaryFiltersStore.vids = value.vidsString;
  },
});

/**
 * A human-readable version of the VID range.
 */
const humanReadableRange = useComputedResultAsync(() =>
  vidRange.value.renderReference(scriptureClient, props.localTid, false, false),
);
</script>
